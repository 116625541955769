import { ContainerMaxWidth, Stack } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';

import { InlineWidget } from 'react-calendly';
import SEO from '../components/seo';
import T from '../components/T';
import Layout from '../layouts/default';

import { Link } from 'gatsby-plugin-intl';
import * as styles from './live-presentations.module.scss';

const Webinars = () => (
  <Layout>
    <SEO
      title="Webinars on how to use Hypersay"
      description="Register to our webinars to learn how to use Hypersay for remote teaching or working. It's absolutely free."
      keywords={[
        'webinar',
        'free webinar',
        'remote learning',
        'remote teaching',
        'remote working',
        'work from home',
        'wfh',
        'video streaming',
        'video calling',
        'live presentation',
        'interactive presentations',
        'live polling',
        'live quiz',
      ]}
      shareImage="share-image-webinars.jpg"
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <div className={styles.highlightContainer}>
        <Stack className={`${styles.highlightColumn}`}>
          <img
            src={withPrefix('/images/MicrophoneColors.svg')}
            alt="interactions"
            style={{ width: '150px' }}
          />
          <div className={styles.betaTitle}>
            <T tagName="h2" id="Webinars" />
          </div>
          <T
            tagName="p"
            id="We are running free webinars twice a week on how to use Hypersay for remote teaching and remote working. Book a free slot below. It's free, did we mention that?"
          />
          <InlineWidget
            styles={{
              height: '1000px',
              width: '100%',
            }}
            url="https://calendly.com/hypersay/hypersay-webinar"
          />
          <T id="If you prefer webinars in other languages: " tagName="p" />
          <Link to="/webinars-ro">Romanian language (limba română)</Link>
        </Stack>
      </div>
    </ContainerMaxWidth>
  </Layout>
);

export default Webinars;
